import React from "react"
import { Helmet } from "react-helmet"
import { graphql, StaticQuery } from "gatsby"
import FontUrl1 from "../../static/fonts/TrajanPro-Bold.otf"
import FontUrl2 from "../../static/fonts/TrajanPro-Regular.otf"
import FontUrl3 from "../../static/fonts/GillSans.ttf"

const Head = () => {
  return (
    <>
      <StaticQuery
        query={graphql`
          {
            allWordpressWpFavicon {
              edges {
                node {
                  url {
                    source_url
                  }
                }
              }
            }
          }
        `}
        render={props => (
          <Helmet htmlAttributes={{ lang: "en" }}>
            <title>Triton charity site</title>
            <link
              rel="icon"
              type="image/png"
              href={props.allWordpressWpFavicon.edges[0].node.url.source_url}
              sizes="16x16"
            />
            <link
              rel="preload"
              as="font"
              href={FontUrl1}
              type="font/otf"
              crossOrigin="anonymous"
            />
            <link
              rel="preload"
              as="font"
              href={FontUrl2}
              type="font/otf"
              crossOrigin="anonymous"
            />
            <link
              rel="preload"
              as="font"
              href={FontUrl3}
              type="font/ttf"
              crossOrigin="anonymous"
            />
          </Helmet>
        )}
      />
    </>
  )
}

export default Head
